import React from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';

import WebChat from './WebChat';

import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
let logoMinimized;
let logoAvatar;
let title;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleFetchToken = this.handleFetchToken.bind(this);
    this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
    this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);
    this.language = props.language
    

    const store = createStore({}, ({ dispatch }) => next => action => {
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        setTimeout(() => {
          dispatch({
            type: 'DIRECT_LINE/POST_ACTIVITY',
            payload: {
              activity: {
                name: 'webchat/join',
                type: 'event',
                value: {
                  language: window.navigator.language
                }
              }
            }
          });
        }, 1000);
      } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        if (action.payload.activity.from.role === 'bot') {
          this.setState(() => ({ newMessage: true }));
        }
      }

      return next(action);
    });

    this.state = {
      minimized: true,
      newMessage: false,
      store,
      styleSet: createStyleSet({
        backgroundColor: 'Transparent'
      }),
      token: null
    };

    logoMinimized = `${publicUrl}/img/lateral-${ this.language.toLowerCase() }.gif`;
    logoAvatar = `${publicUrl}/img/avatar.png`;
    title = this.language.toLowerCase() === 'br' ? 'Pergunte para Tehi' : 'Pregunte a Tehi';
  }

  async handleFetchToken() {
    if (!this.state.token) {
      this.setState(() => ({ token: process.env[`REACT_APP_TOKEN_${this.language.toUpperCase() }`]}));
    }
  }

  handleMaximizeButtonClick() {
    this.setState(() => ({
      minimized: false,
      newMessage: false
    }));
  }

  handleMinimizeButtonClick() {
    this.setState(() => ({
      minimized: true,
      newMessage: false
    }));
  }

  render() {
    const { state: {
      minimized,
      store,
      styleSet,
      token
    } } = this;

    return (
      <div className="minimizable-web-chat disable-selection">
        {
          minimized ?
            <div className="maximize">
              <img alt="minimized bot" className="img-responsive" src={logoMinimized} onClick={this.handleMaximizeButtonClick} />
            </div>
            :
            <div className="chat-box right">
              <header>
                <img alt="avatar tehi" className="img-chat" src={logoAvatar} onClick={this.handleMaximizeButtonClick} />
                <div className="filler">
                  <p>{title}</p>
                </div>
                <button className="minimize" onClick={this.handleMinimizeButtonClick} >
                  <span className="ms-Icon ms-Icon--ChromeMinimize" />
                </button>
              </header>
              <WebChat
                className="react-web-chat"
                onFetchToken={this.handleFetchToken}
                store={store}
                styleSet={styleSet}
                token={token}
                language={this.language}
              />
            </div>
        }
      </div>
    );
  }
}
