import React, { Component } from 'react';
import MinimizableWebChat from './MinimizableWebChat';

class App extends Component {
  constructor(props) {
    super(props);
    console.log(JSON.stringify(props))
    this.language = props.language
  }
  render() {
    return (
      <div className="App">
        <MinimizableWebChat language={this.language}/>
      </div>
    );
  }
}

export default App;
