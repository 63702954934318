import memoize from 'memoize-one';
import React from 'react';
import ReactWebChat, { createDirectLine, createStyleSet, connectToWebChat } from 'botframework-webchat';
import './WebChat.css';

const { css } = window.Glamor;

const ACTIVITY_WITH_FEEDBACK_CSS = css({
  minHeight: 60,
  position: 'relative',
  '& > .activity': {
    paddingLeft: 40
  },
  '& > .button-bar': {
    listStyleType: 'none',
    margin: '0 0 0 10px',
    padding: 0,
    position: 'absolute',
    '& > li > button': {
      background: 'White',
      border: 'solid 1px #E6E6E6',
      cursor: 'pointer',
      marginBottom: 2,
      padding: '2px 5px 5px'
    }
  }
});

let message, locale;

class ActivityWithFeedback extends React.Component {

  constructor(props) {
    super(props);
    this.state = { used: '' };
  }

  handleDownvoteButton = () => {
    this.setState({ used: true });
    this.props.postActivity({ type: 'message', name: 'evaluate-activity', value: { id: this.props.activityID.id, question: this.props.question, vote: 'DOWN' } });
  }
  handleUpvoteButton = () => {
    this.setState({ used: true });
    this.props.postActivity({ type: 'message', name: 'evaluate-activity', value: { id: this.props.activityID.id, question: this.props.question, vote: "UP" } })
  }
  render() {
    const { props } = this;

    return (
      <div id='feedback' className={ACTIVITY_WITH_FEEDBACK_CSS}>
        {!this.state.used ? <ul className="button-bar">
          <li><button disabled={this.state.used} onClick={this.handleUpvoteButton}><span role="img" aria-label="up vote">👍</span></button></li>
          <li><button disabled={this.state.used} onClick={this.handleDownvoteButton}><span role="img" aria-label="down vote">👎</span></button></li>
        </ul> : <ul></ul>

        }
        <div className="activity">
          {props.children}
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.language = props.language
    this.createDirectLine = memoize(token => createDirectLine({ token }));

    this.state = {
      styleSet: createStyleSet({
        backgroundColor: 'Transparent',
        hideUploadButton: true,
        fontSizeSmall: '50%'
      })
    };

    this.ConnectedActivityWithFeedback = connectToWebChat(
      ({ postActivity }) => ({ postActivity })
    )(props => <ActivityWithFeedback {...props} />)

    var lastQuestion = null;
    this.activityMiddleware = () => next => card => {

      if (card.activity.from.role !== 'bot') {
        lastQuestion = card.activity.text;
      }

      if (card.activity.from.role === 'bot') {
        return (
          children =>
            <this.ConnectedActivityWithFeedback activityID={card.activity} question={lastQuestion} used={false}>
              {next(card)(children)}
            </this.ConnectedActivityWithFeedback>
        );
      } else {
        return next(card);
      }
    };
  }

  componentDidMount() {
    !this.props.token && this.props.onFetchToken();
  }

  render() {
    const {
      props: { className, store, token },
      state: { styleSet }
    } = this;

    if (this.language.toLowerCase() === 'br') {
      message = "Aguarde enquanto nos conectamos...";
      locale = "pt-BR";
    } else {
      message = "Espere mientras lo conectamos...";
      locale = "es-ES";
    }

    return (
      token ?
        <ReactWebChat
          activityMiddleware={this.activityMiddleware}
          className={`${className || ''} web-chat`}
          directLine={this.createDirectLine(token)}
          store={store}
          styleSet={styleSet}
          locale={locale}
        />
        :
        <div className={`${className || ''} connect-spinner`}>
          <div className="content">
            <div className="icon">
              <span className="ms-Icon ms-Icon--Robot" />
            </div>
            <p>{message}</p>
          </div>
        </div>
    );
  }
}
